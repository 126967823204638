import { template as template_ae6d70743ad54d79bc1ab2662f1d3029 } from "@ember/template-compiler";
const FKLabel = template_ae6d70743ad54d79bc1ab2662f1d3029(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
