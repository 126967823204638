import { template as template_104340d8dff04b54aa483340335a0a39 } from "@ember/template-compiler";
import DButton from "discourse/components/d-button";
const BackButton = template_104340d8dff04b54aa483340335a0a39(`
  <DButton
    @action={{@onGoBack}}
    @label="topic.timeline.back"
    @title="topic.timeline.back_description"
    class="btn-primary btn-small back-button"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default BackButton;
