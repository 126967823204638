import { template as template_11220f08906749e4be00f80c8e02c820 } from "@ember/template-compiler";
const SidebarSectionMessage = template_11220f08906749e4be00f80c8e02c820(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
