import { template as template_b563d95f9b2e4fa687a3ba25d6082231 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ViewsCell = template_b563d95f9b2e4fa687a3ba25d6082231(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="views"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="views"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ViewsCell;
