import { template as template_3fa9fd9c62754851a0bf20b7020e2762 } from "@ember/template-compiler";
const WelcomeHeader = template_3fa9fd9c62754851a0bf20b7020e2762(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
